import { LOAD_HISTORY_DATA } from "./../../actions/history"; 

const initialState = {
    history_data: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_HISTORY_DATA:
            return { ...state, history_data: action.payload.items };
        default:
            return state;
    }
}