import { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form';

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from "../../../@core/redux/actions/profile";

// Icon
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// @Utility
import validateInput from "@utility/app/validateInput";

// Component
import { Grid } from '@mui/material';
import SnackbarSet from '../SnackbarSet';
import BackButton from "views/components/BackButton";

// CSS
import "assets/css/Profile.css";

export default function ChangePassword() {
    const dispatch = useDispatch();
    const childRef = useRef();
    const homeData = useSelector(state => state.reduxHome.result);
    const [passwordSmsContent, setPasswordSmsContent] = useState(null);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [password, setPassword] = useState({
        password: "",
        showPassword: false
    });

    const onSubmit = async (data) => {
        const { passwordOld, passwordNew, passwordNewAgain } = data;
        const pathUrl = passwordSmsContent === 1 ? "/customer/profile/reset-password-sms" : "/customer/profile/reset-password" 
        let myJson = {
            password_old: passwordSmsContent === 1 ? "" : passwordOld,
            password: passwordNew,
            password_confirm: passwordNewAgain
        }

        dispatch(changePassword(myJson, pathUrl, passwordSmsContent));
        reset("");
    }

    // แสดงหรือซ่อนรหัสผ่าน
    const handleClickShowPassword = () => {
        setPassword({ ...password, showPassword: !password.showPassword });
    };

    const renderErrorPassword = () => {
        if (errors.password?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุรหัสผ่านมากกว่า 4 ตัวอักษร
                </div>
            );
        }

        return null;
    };

    const renderContent = () => {
        return (
            <>
                <SnackbarSet ref={childRef} />
                <div className="addBankContainer">
                    {
                        passwordSmsContent !== 1 && (
                            <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                <Grid item xs={1}>
                                    <BackButton backPage="goBack"></BackButton>
                                </Grid>
                                <Grid item>
                                    <div className="boxTitle center">
                                        เปลี่ยนรหัสผ่าน
                                    </div>
                                </Grid>
                                <Grid item xs={1}>
                                    &nbsp;
                                </Grid>
                            </Grid>
                        )
                    }


                    <div className="contentMediumMarginTop">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                passwordSmsContent !== 1 && (
                                    <div className="inputPaddingTop">
                                        <br />
                                        <div className="inputTextLabel" style={{ textAlign: "left", color: passwordSmsContent !== null ? "var(--bg-white)" : "var(--bg-text-main)" }}>
                                            รหัสผ่านเก่า
                                        </div>
                                        <Grid container alignItems="center" className="inputStyles center">
                                            <Grid item xs={2}>
                                                <LockIcon className="iconSize" />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <input type={password.showPassword ? 'text' : 'password'} className="inputStyles inputStylesBorderNone" placeholder="รหัสผ่านเก่า" {...register("passwordOld", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} maxLength="20" onKeyPress={(event) => validateInput(event, "textNumber")} required />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <div
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {password.showPassword ? <Visibility className="iconSize" /> : <VisibilityOff className="iconSize" />}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {
                                            renderErrorPassword()
                                        }
                                    </div>
                                )
                            }
                            <div className="inputPaddingTop">
                                <div className="inputTextLabel" style={{ textAlign: "left", color: passwordSmsContent !== null ? "var(--bg-white)" : "var(--bg-text-main)" }}>
                                    รหัสผ่านใหม่
                                </div>
                                <Grid container alignItems="center" className="inputStyles center">
                                    <Grid item xs={2}>
                                        <LockIcon className="iconSize" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input type={password.showPassword ? 'text' : 'password'} className="inputStyles inputStylesBorderNone" placeholder="รหัสผ่านใหม่" {...register("passwordNew", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} maxLength="20" onKeyPress={(event) => validateInput(event, "textNumber")} required />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div
                                            onClick={handleClickShowPassword}
                                        >
                                            {password.showPassword ? <Visibility className="iconSize" /> : <VisibilityOff className="iconSize" />}
                                        </div>
                                    </Grid>
                                </Grid>
                                {
                                    renderErrorPassword()
                                }
                                <div style={{ textAlign: "left", paddingTop: "5px", color: passwordSmsContent !== null ? "var(--bg-white)" : "var(--bg-text-main)" }}>
                                    <span>รหัสผ่านมากกว่า 4 ตัวอักษร</span>
                                </div>
                            </div>
                            <div className="inputPaddingTop">
                                <div className="inputTextLabel" style={{ textAlign: "left", color: passwordSmsContent !== null ? "var(--bg-white)" : "var(--bg-text-main)" }}>
                                    รหัสผ่านใหม่อีกครั้ง
                                </div>
                                <Grid container alignItems="center" className="inputStyles center">
                                    <Grid item xs={2}>
                                        <LockIcon className="iconSize" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input type={password.showPassword ? 'text' : 'password'} className="inputStyles inputStylesBorderNone" placeholder="รหัสผ่านใหม่อีกครั้ง" {...register("passwordNewAgain", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} maxLength="20" onKeyPress={(event) => validateInput(event, "textNumber")} required />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div
                                            onClick={handleClickShowPassword}
                                        >
                                            {password.showPassword ? <Visibility className="iconSize" /> : <VisibilityOff className="iconSize" />}
                                        </div>
                                    </Grid>
                                </Grid>
                                <div style={{ textAlign: "left", paddingTop: "5px", color: passwordSmsContent !== null ? "var(--bg-white)" : "var(--bg-text-main)" }}>
                                    <span>รหัสผ่านมากกว่า 4 ตัวอักษร</span>
                                </div>
                                {
                                    renderErrorPassword()
                                }
                            </div>

                            <div className="center">
                                <div className="inputPaddingTop" style={{ paddingTop: "10px" }}>
                                    <button type="submit" className="btn btnConfirm btnHover btnWidthLarge btnRadius textBoldHight">
                                        ยืนยัน
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    };

    useEffect(() => {
        if (homeData !== null) {
            if (homeData?.auth_sms !== undefined && homeData?.auth_sms !== null) {
                if (homeData?.auth_sms === 1 || homeData?.auth_sms === true) {
                    setPasswordSmsContent(1);
                }
            }
        }
    }, [homeData]);

    return (

        <div>
            {renderContent()}
        </div>
    )
}
