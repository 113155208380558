// Style & CSS
import "assets/css/Modal.css";

// Icon
import CloseIcon from '@mui/icons-material/Close';

export default function ModalAlert(props) {
    const close = () => {
        props.onShow(false);
    };

    const closeTab = () => {
        window.close()
    }

    return (
        props.show && (
            <div id="modal">
                <div className="contentPosition zIndexHight" style={{ zIndex: "1800" }}>
                    <div className="boxBody">
                        <div className="box">
                            <div className="positionRelative">
                            {
                                props.closeBtn ?
                                <div className="closeButtonFull" onClick={() => close(false)}>
                                    <CloseIcon className="iconImgFull"></CloseIcon>
                                </div>
                                :
                                <div className="closeButtonFull" onClick={() => closeTab()}>
                                    <CloseIcon className="iconImgFull"></CloseIcon>
                                </div>
                            }
                            </div>

                            <div className="boxContent" style={{ background: "#000000" }}>
                                {
                                    props.children
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="fixedBackdrop zIndexMedium" onClick={close} style={{ zIndex: "1600", opacity: ".9" }}></div>                
            </div>
        )
    );
}