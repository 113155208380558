import Container from '@mui/material/Container';

export default function WrapperContainer(props) {

    return (
        <Container className={props.className} style={props.style} maxWidth={props.maxWidth}>
            {
                props.children
            }
        </Container>
    );
}