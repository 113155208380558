// @utility
import { cleatStorage, getStorage, setStorage } from "@utility/app/storage";

function checkStatusCode(statusCode) {
    if (statusCode === 403 || statusCode === 402 || statusCode === 401 || statusCode === 400) {
        let market = getStorage("market");
        let agent_contact = getStorage("agent_contact");
        let agent_logo = getStorage("agent_logo");
        const getSetting = getStorage("setting");
        cleatStorage();
        setStorage("market", market, 30);
        setStorage("agent_contact", agent_contact);
        setStorage("agent_logo", agent_logo);
        setStorage("setting", getSetting);
        window.location.reload(true);
    } else {
        return true;
    }
}

export {
    checkStatusCode
}