// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_SEACH_GAME_POPUP = 'app/SET_SEACH_GAME_POPUP';
const SET_SEACH_GAME_DATA = 'app/SET_SEACH_GAME_DATA';

function setSearchGamePopup(data) {
    return {
        type: SET_SEACH_GAME_POPUP,
        payload: {
            items: data
        }
    }
}

function searchGameData(text) {
    return dispatch => {
        const myJson = {
            "filter": text,
            "limit": -1,
            "offset": 0
        };

        dispatch(setStatusSpinner({status: true, textProcess: null}));

        apiServices(myJson, "post", `/general/list/game/all/99`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0 || statusCode === 23) {
                dispatch({
                    type: SET_SEACH_GAME_DATA,
                    payload: {
                        items: result
                    }
                });
            }
        });
    }
}

function setSearchGameData(data) {
    return {
        type: SET_SEACH_GAME_DATA,
        payload: {
            items: data
        }
    }
}


export {
    SET_SEACH_GAME_POPUP,
    SET_SEACH_GAME_DATA,
    setSearchGamePopup,
    searchGameData,
    setSearchGameData
}