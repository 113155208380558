import { lazy } from 'react';

// Redux
// import { useSelector } from 'react-redux';

// Routes page
const Fes5567 = lazy(() => import("./templates/fes5567/PageRoute"));

const ViewsRoutes = () => {
    const routesRouter = () => {
        return <Fes5567 />;
    };

    return routesRouter();
};

export default ViewsRoutes;