import { 
    LOAD_DATA_CASHBACK
}  from './../../actions/cashback/index';

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOAD_DATA_CASHBACK:
            return { ...state, result: action.payload.items };
        default: 
            return state;
    } 
}