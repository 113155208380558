// Redux & Action
import { useDispatch } from 'react-redux';
import { setPasswordSmsPopup } from "@core/redux/actions/app/password";

// Component
import ModalApp from "./ModalFull";
import ChangePassword from "./Profile/ChangePassword";

// Style & CSS
import "assets/css/Input.css";

export default function PasswordSms({ show }) {
    const dispatch = useDispatch();

    const onShow = (val) => {
        dispatch(setPasswordSmsPopup(val));
    };

    return (
        <ModalApp show={show} onShow={onShow}>
            <div className="boxTitle" style={{ color: "var(--bg-white)" }}>
                กรุณากำหนดรหัสผ่านใหม่
            </div>
            <div className="boxMarginTop">
                <ChangePassword />
            </div>
        </ModalApp>
    );
}