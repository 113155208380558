import { useState } from 'react';

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";
import useParameterUrl from "@utility/hooks/useParameterUrl";
// import useOnlineStatus from "@utility/hooks/useOnlineStatus";

// Auth & JWT
import { isTokenGeneral, isUserLoggedIn } from "@core/auth/utils";
import { checkExpireTokenGeneral, loadTokenGeneral } from "@core/auth/jwt/jwtService";

// Component
import Layouts from "views/Layouts";
import ConfirmWarning from "views/components/ConfirmWarning";
import ErrorApp from "views/components/Error/index";

// Style & CSS
import './App.css';
import "assets/css/Style.css";
import "assets/css/Button.css";
import "assets/css/Animation.css";

function App() {
  const [loadRoutes, setLoadRoutes] = useState(false);
  const [statusLayout, setStatusLayout] = useState(null);
  const [textData, setTextData] = useState(false);
  const [dataSettingError, setDataSettingError] = useState(null);
  const [dataSettingOffline, setDataSettingOffline] = useState(null);
  const parsed = useParameterUrl();
  const { msg } = parsed;
  const domain = window.location.host;
  // const domain = "nextspin711-b.711testapi.com";
  const agent_code = process.env.REACT_APP_DEFAULT_AGENT_CODE;
  // const isOnline = useOnlineStatus();
  // const [errorMode, setErrorMode] = useState("");
  // const errorMode = "";

  const setTokenGeneral = () => {
    let myJson = { "domain": domain };
    if (agent_code !== "" && agent_code !== undefined) {
      myJson = {
        "agent_code": agent_code,
        "agent_hash": agent_code
      };
    }

    loadTokenGeneral(myJson, setLoadRoutes, setDataSettingError, setDataSettingOffline);
  };

  const renderContent = () => {
    // if (!isOnline || errorMode === "permission") {
    //   return <ErrorApp typeError="offline"></ErrorApp>;
    // }

    if (statusLayout !== null) {
      if (statusLayout) {
        if (!dataSettingError && !dataSettingOffline) {
          if (loadRoutes === true) {
            return (
              <Layouts loadRoutes={loadRoutes} domain={domain}></Layouts>
            );
          }

          return null;
        }

        if (dataSettingOffline) {
          return <ErrorApp typeError="offline"></ErrorApp>;
        }

        if (dataSettingError) {
          return <ErrorApp typeError="noData"></ErrorApp>;
        }

        return null;
      }

      return (
        <ConfirmWarning text={textData} close={true}></ConfirmWarning>
      );
    }

    return null;
  };

  useEffectOnce(() => {
    try {
      if (msg !== null && msg !== undefined && msg !== "undefined" && msg !== "") {
        setTextData(msg);
        setStatusLayout(false);
      }
      else {
        setStatusLayout(true);

        const renderToken = () => {
          if (isUserLoggedIn()) {
            setLoadRoutes(true);
            setDataSettingError(false);
            setDataSettingOffline(false);
          }
          else {
            if (isTokenGeneral()) {
              if (checkExpireTokenGeneral()) {
                setTokenGeneral();
              }
              else {
                setDataSettingError(false);
                setDataSettingOffline(false);
                setLoadRoutes(true);
              }
            }
            else {
              setTokenGeneral();
            }
          }
        }

        renderToken();
      }
    } catch (error) {
      setDataSettingOffline(true);
    }
  }, []);

  // useEffect(() => {
  //   // รับข้อมูลจาก service worker
  //   navigator.serviceWorker.addEventListener('message', (event) => {
  //     if (event.data.type === 'errorMode') {
  //       const errorMode = event?.data?.isErrorMode;
  //       setErrorMode((errorMode === undefined || errorMode === null) ? "" : errorMode);
  //     }
  //   });
  // }, []);

  return renderContent();
}

export default App;
