// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const LOAD_DATA_CAMP_ALL = 'app/camp/LOAD_DATA_CAMP_ALL';
const SET_SELECT_CAMP = 'app/camp/SET_SELECT_CAMP';

function loadDataCampAll(myJson) {
    return dispatch => {
        apiServices({}, "get", `/general/list/camp/${myJson.type}`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_DATA_CAMP_ALL,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_DATA_CAMP_ALL,
                    payload: {
                        items: []
                    }
                })
            }
        });
    }
}

function setSelectCamp(data) {
    return {
        type: SET_SELECT_CAMP,
        payload: {
            items: data
        }
    }
}


export {
    LOAD_DATA_CAMP_ALL,
    SET_SELECT_CAMP,
    loadDataCampAll,
    setSelectCamp
}