import { OPEN_PROMOTION_POPUP, LOAD_PROMOTION_ALL } from './../../actions/promotion/index';

const initialState = {
    tab: "promotionPopup",
    show: false,
    promotionId: 0,
    promotionSelect: false,
    promotionData: null,
    promotionItem: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_PROMOTION_POPUP:
            return { ...state, ...action.payload.items };
        case LOAD_PROMOTION_ALL:
            return { ...state, promotionItem: action.payload.items };
        default:
            return state;
    }
}