import { useRef } from "react";

// JWT & Auth
import { isUserLoggedIn } from "@core/auth/utils";
import { getToken } from "@core/auth/utils";

// Component
import ProgressiveImage from "views/components/ProgressiveImage";
import GotoPage from "views/components/GotoPage";

export default function GameImage({ item, checkPromotion }) {
    const pageRef = useRef();

    const renderBagde = (item) => {
        if (item.status === 3) {
            return (
                <div className="ribbon ribbonDanger">
                    ปิดปรับปรุง
                </div>
            );
        }
        else if (item.status === 2) {
            if (item.spin === 1 || item.promotion === 1) {
                if (checkPromotion === 1 && item.promotion === 1) {
                    return (
                        <div className="ribbon ribbonDanger">
                            ติดโปรโมชั่น
                        </div>
                    );
                }
                else if (item.spin === 1) {
                    return (
                        <div className="ribbon ribbonWarning">
                            ซื้อฟรีสปิน
                        </div>
                    );
                }
            }
            else {
                if (item.specials !== "") {
                    let specialType = item.specials.split(",");

                    if (specialType.length > 1) {
                        return (
                            <div className="ribbon ribbonPrimary">
                                มาใหม่
                            </div>
                        );
                    }
                    else {
                        let selectTypeNew = specialType.find((str) => str === "new");
                        let selectTypeHot = specialType.find((str) => str === "hot");

                        if (selectTypeNew !== undefined) {
                            return (
                                <div className="ribbon ribbonPrimary">
                                    มาใหม่
                                </div>
                            );
                        }

                        if (selectTypeHot !== undefined) {
                            return (
                                <div className="ribbon ribbonYellow">
                                    มาแรง
                                </div>
                            );
                        }
                    }
                }
            }
        }
        else {
            return null;
        }
    };

    const renderGameImage = () => {
        if (item === null) return null;

        return (
            <div className="positionRelative">
                <div className="ribbonPosition">
                    {
                        renderBagde(item)
                    }
                </div>

                {
                    (item.status !== 2 || (checkPromotion === 1 && item.promotion === 1)) ?
                        <ProgressiveImage
                            className="gameImage cursorPointer positionRelative"
                            placeholder="/images/bluring/215x280.jpg"
                            src={`${item.image}`}
                            style={{ opacity: "0.6" }}
                        ></ProgressiveImage>
                        :
                        isUserLoggedIn() ?
                            <a href={genareateUrl(item)} target="_blank" rel="noreferrer">
                                <ProgressiveImage
                                    className="gameImage cursorPointer positionRelative"
                                    placeholder="/images/bluring/215x280.jpg"
                                    src={`${item.image}`}
                                ></ProgressiveImage>
                            </a>
                            :
                            <ProgressiveImage
                                className="gameImage cursorPointer positionRelative"
                                placeholder="/images/bluring/215x280.jpg"
                                src={`${item.image}`}
                                onClick={() => popupGameDemo(item)}
                            ></ProgressiveImage>
                }

                <div className="positionAbsolute gameNameBottom widthFull">
                    <div className="gameNameBox">
                        <div className="gameName center">
                            <div className="gameNameSzie">
                                {
                                    item.name
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const popupGameDemo = (item) => {
        pageRef.current.openGame("playGame", item);
    };

    const genareateUrl = (item) => {
        let mode = "demo";

        if (isUserLoggedIn()) {
            mode = "real";
        }

        if(item !== undefined && item !== null) {
            let urlOpenGame = "";

            if (item?.is_html) {
                urlOpenGame = `${window.location.origin}/open-game/html?mode=${mode}&camp=${item?.camp.toLowerCase()}&code=${(item?.game_code === undefined || item?.game_code === null) ? item.code : item?.game_code}`;
            }
            else {
                urlOpenGame = `${process.env.REACT_APP_API_URL}/general/generate/open-game/${mode}/${(item?.game_code === undefined || item?.game_code === null) ? item.code : item?.game_code}?url=${window.location.origin}&token=${getToken()}`;
            }

            return urlOpenGame;
        }
    };

    return (
        <>
            <GotoPage ref={pageRef}></GotoPage>

            {
                renderGameImage()
            }
        </>
    );
}