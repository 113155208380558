import {
    SET_GAME_ALL,
    SET_GAME_DEMO,
    SET_SCROLL_POSITION,
    SET_SCROLL_MOVE,
    SET_OPEN_GAME
} from './../../actions/game/index';

const initialState = {
    itemsGameList: false,
    itemGameDemo: null,
    scrollStatus: false,
    scrollMove: false,
    gamePlay: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_GAME_ALL:
            return { ...state, itemsGameList: action.payload.items };
        case SET_GAME_DEMO:
            return { ...state, itemGameDemo: action.payload.items };
        case SET_SCROLL_POSITION:
            return { ...state, scrollStatus: action.payload.items };
        case SET_SCROLL_MOVE:
            return { ...state, scrollMove: action.payload.items };
        case SET_OPEN_GAME:
            return { ...state, gamePlay: action.payload.items };
        default:
            return state;
    }
}