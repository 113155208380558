import {
    LOAD_DATA_DEPOSIT,
    LOAD_BANK_CUSTOMER
} from './../../actions/deposit/index';

const initialState = {
    result_home: null,
    bank_customer: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_DEPOSIT:
            return { ...state, result_home: action.payload.items };
        case LOAD_BANK_CUSTOMER:
            return { ...state, bank_customer: action.payload.items };
        default:
            return state;
    }
}