// @Utility
import alertText from "@utility/app/alertText";

// Redux & Action
import { loadHomeData } from "@core/redux/actions/home/index";
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";

// Services
import { apiServices } from "@core/services/appService";

const LOAD_WHEEL_SPIN_DATA = 'app/mini-game/LOAD_WHEEL_SPIN_DATA';

function loadWheelSpinData(spinDataAll) {
    return dispatch => {
        if (spinDataAll === null) {
            dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังโหลดข้อมูล กรุณารอสักครู่" }));
        }

        apiServices({}, "get", "/customer/minigame/wheel/data").then((data) => {
            let snackbarJson = {};
            const { code: statusCode } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_WHEEL_SPIN_DATA,
                    payload: {
                        items: data.result
                    }
                });
            }
            else {
                snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));

                dispatch({
                    type: LOAD_WHEEL_SPIN_DATA,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function clickWheelSpin(homeData, setElement) {
    return dispatch => {
        if (homeData.promotion_status === 0) {
            if (homeData.wallets.length > 0) {
                if (homeData.wallets[0].balance >= 50) {
                    dispatch(setStatusSpinner({ status: true, textProcess: "กำลังตรวจสอบข้อมูล กรุณารอสักครู่" }));

                    apiServices({}, "post", "/customer/minigame/wheel/bet").then((data) => {
                        let snackbarJson = {};
                        const { code: statusCode, result } = data;
                         dispatch(setStatusSpinner({ status: false, textProcess: null }));

                        if (statusCode === 200 || statusCode === 0) {
                            dispatch(loadHomeData());

                            if (result !== null) {
                                setTimeout(() => {
                                    setElement(result);
                                }, 1000);
                            }
                        }
                        else {
                            snackbarJson = alertText(statusCode);
                            dispatch(setDataAlert({
                                type: snackbarJson.statusAlert,
                                title: snackbarJson.titleAlert,
                                text: snackbarJson.textAlert,
                                action: true,
                                redirect: null,
                                show: true,
                                buttonText: "ตกลง"
                            }));
                        }
                    });
                }
                else {
                    dispatch(setDataAlert({
                        type: "warning",
                        title: "แจ้งเตือน",
                        text: "คุณมีเเต้มไม่เพียงพอ",
                        action: true,
                        redirect: null,
                        show: true,
                        buttonText: "ตกลง"
                    }));
                }
            }
            else {
                dispatch(setDataAlert({
                    type: "warning",
                    title: "แจ้งเตือน",
                    text: "ไม่พบข้อมูลกระเป๋า กรุณาติดต่อผู้ดูแลระบบ",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        }
        else {
            dispatch(setDataAlert({
                type: "warning",
                title: "แจ้งเตือน",
                text: "ไม่สามารถหมุนกงล้อได้ เนื่องจากติดเงื่อนไขรับโปร",
                action: true,
                redirect: null,
                show: true,
                buttonText: "ตกลง"
            }));
        }
    }
};

export {
    LOAD_WHEEL_SPIN_DATA,
    loadWheelSpinData,
    clickWheelSpin
}