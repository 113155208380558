// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const SET_HOME_DATA = "app/home/SET_HOME_DATA";
const SET_HOME_MENU_TYPE = 'app/home/SET_HOME_MENU_TYPE';

function loadHomeData() {
    return dispatch => {
        apiServices({}, "get", `/customer/profile`).then((data) => {
            const { code: statusCode, result } = data;

            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: SET_HOME_DATA,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_HOME_DATA,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function setHomeMenuType(data) {
    return {
        type: SET_HOME_MENU_TYPE,
        payload: {
            items: data
        }
    }
}

function reloadCredit(homeData, setSpin) {
    return dispatch => {
        apiServices({}, "get", `/customer/profile/balance`).then((data) => {
            setSpin(false);
            const { code: statusCode, result } = data;

            if (statusCode === 200 || statusCode === 0) {
                let dataObj = {
                    ...homeData,
                    balance: result.balance
                };

                dispatch({
                    type: SET_HOME_DATA,
                    payload: {
                        items: dataObj
                    }
                })
            }
        });
    }
}

export {
    SET_HOME_DATA,
    SET_HOME_MENU_TYPE,
    loadHomeData,
    setHomeMenuType,
    reloadCredit
}