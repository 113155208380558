function loadLiveChat(params) {
    window.__lc = window.__lc || {};
    window.__lc.license = params;

    setLiveChat(window, document, [].slice)
}

function setLiveChat(n, t, c) {
    function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n)
    }

    var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () { i(["on", c.call(arguments)]) },
        once: function () { i(["once", c.call(arguments)]) },
        off: function () { i(["off", c.call(arguments)]) },
        get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) },
        call: function () { i(["call", c.call(arguments)]) },
        init: function () {
            var elmScript = t.createElement("script");
            elmScript.async = !0;
            elmScript.type = "text/javascript";
            elmScript.src = "https://cdn.livechatinc.com/tracking.js";
            t.head.appendChild(elmScript)
        }
    };

    !n.__lc.asyncInit && e.init();
    n.LiveChatWidget = n.LiveChatWidget || e
    n.LiveChatWidget.call("hide");
    n.LiveChatWidget.call("minimized");
}

export default loadLiveChat;