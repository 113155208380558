import { LOAD_DATA_WITHDRAW, SET_WITHDRAW_RANK_DATA } from './../../actions/withdraw/index';

const initialState = {
    result_home: false,
    rank: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_WITHDRAW:
            return { ...state, result_home: action.payload.items };
        case SET_WITHDRAW_RANK_DATA:
            return { ...state, rank: action.payload.items };
        default:
            return state;
    }
}