import * as jwtService from "@core/auth/jwt/jwtService";

const isToken = () => { // Check Token
  let customerToken = jwtService.getCustomerToken();

  if(customerToken === null || customerToken === undefined) { 
    customerToken =  jwtService.getGeneralToken();
    if(customerToken === null || customerToken === undefined || customerToken === "undefined") { 
      return false;
    }
    else {
      return true;
    }
  }
  else {
    return true;
  }
}

const isTokenGeneral = () => { // Check Token General
  let customerToken = jwtService.getGeneralToken();

  if(customerToken === null || customerToken === undefined || customerToken === "undefined") { 
    return false;
  }
  else {
    return true;
  }
}

const getToken = () => {
  let customerToken = jwtService.getCustomerToken();

  if(customerToken === null || customerToken === undefined) { 
    customerToken =  jwtService.getGeneralToken();
    if(customerToken === null || customerToken === undefined || customerToken === "undefined") { 
      return undefined;
    }
    else {
      return customerToken;
    }
  }
  else {
    return customerToken;
  }
}

const isUserLoggedIn = () => {
  const customerToken = jwtService.getCustomerToken();
  if(customerToken === null || customerToken === undefined || customerToken === "undefined") { 
    return false;
  }
  else {
    return true;
  }
}

export {
  isToken,
  isUserLoggedIn,
  getToken,
  isTokenGeneral
}