import {
    SET_DATA_CMS
} from '../../actions/app/cms';

const initialState = {
    cms: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_CMS:
            return { ...state, cms: action.payload.items };
        default:
            return state;
    }
}