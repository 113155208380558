const SET_PASSWORD_SMS_POPUP = 'app/SET_PASSWORD_SMS_POPUP';

function setPasswordSmsPopup(data) {
    return {
        type: SET_PASSWORD_SMS_POPUP,
        payload: {
            items: data
        }
    }
}

export {
    SET_PASSWORD_SMS_POPUP,
    setPasswordSmsPopup
}