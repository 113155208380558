import axios from 'axios';

// @Utility
import apiDomain from "@utility/app/apiDomain";

const apiUrl = process.env.REACT_APP_API_URL;
const reqUrl = apiUrl === undefined || apiUrl === null || apiUrl === "" ? apiDomain(window.location.host) : apiUrl;

axios.defaults.baseURL = reqUrl;

// console.log("process.env.REACT_APP_API_URL : ", process.env.REACT_APP_API_URL);
// console.log("window.location.host : ", window.location.host);
// console.log("reqUrl : ", reqUrl);
// console.log("apiDomain(window.location.host) : ", apiDomain(window.location.host));

const apiAxios = axios.create({
  baseURL: reqUrl
});

apiAxios.interceptors.request.use(
  (config) => {
    // ตรวจสอบสถานะการเชื่อมต่ออินเตอร์เน็ต
    if (!navigator.onLine) {
      throw new Error('No internet connection');
    }

    return config;
  },
  (error) => {
    throw error;
  }
);

apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  }
);

export default apiAxios;
