import { OPEN_AUTHEN } from './../../actions/auth/index';

const initialState = {
    tab: "login",
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_AUTHEN:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}