import setCookie from "./setCookie";
import getCookie from "./getCookie";

function setStorage(key, value, day = 30) {
    try {
        localStorage.setItem(key, value);
    } 
    catch(e) {
        setCookie(day, `${key}=${value}`);
    }
}
  
function getStorage(key) {
    try {
        return localStorage.getItem(key);
    } 
    catch(e) {
        return getCookie(key);
    }
}

function removeStorage(key) {
     try {
        localStorage.removeItem(key);
    } 
    catch(e) {
        document.cookie = key +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

function cleatStorage() {
     try {
       localStorage.clear();
    } 
    catch(e) {
        let theCookies = document.cookie.split(';');
        for (let i = 0 ; i < theCookies.length; i++) {
            document.cookie = theCookies[i] +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    }
}
  
export {
    setStorage,
    getStorage,
    removeStorage,
    cleatStorage
};