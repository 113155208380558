const data = {
    headerType: "Bearer",
    authorization: "Authorization",
    contentType: "application/x-www-form-urlencoded",
    method_get: "get",
    method_post: "post",
    method_put: "put",
    method_delete: "delete"
};

export default data;
