import {
    SET_HOME_DATA,
    SET_HOME_MENU_TYPE
} from './../../actions/home/index';

const initialState = {
    result: null,
    menu: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_HOME_DATA:
            return { ...state, result: action.payload.items };
        case SET_HOME_MENU_TYPE:
            return { ...state, menu: action.payload.items };
        default:
            return state;
    }
}