const SET_STATUS_SPINNER = 'app/SET_STATUS_SPINNER';

function setStatusSpinner(data) {
    return {
        type: SET_STATUS_SPINNER,
        payload: {
            items: data
        }
    }
}

export {
    SET_STATUS_SPINNER,
    setStatusSpinner
}