import { useForm } from 'react-hook-form';

// @utility
import validateInput from "@utility/app/validateInput";

// Redux & Action
import { useDispatch } from 'react-redux';
import { rememberPassword } from "@core/redux/actions/auth/index";

// Component
import Grid from '@mui/material/Grid';
// import BackButton from "./../../components/BackButton";

// Icon
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';

export default function RememberApp({ switchChanel }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        dispatch(rememberPassword(data, switchChanel));
    };

    const renderErrorUsername = () => {
        if (errors.username?.type === "pattern") {
            return (
                <div className="validateError">
                    ยูสเซอร์เนมที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return null;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inputPaddingTop">
                <br />
                <div className="boxTitle" style={{ color: "var(--bg-white)" }}>
                    ลืมรหัสผ่าน ?
                </div>
                <div className="inputPaddingTop">
                    <div className="inputTextLabel">
                        เบอร์โทรศัพท์
                    </div>
                    <Grid container alignItems="center" className="inputStyles center">
                        <Grid item xs={2}>
                            <PhoneIcon className="iconSize" />
                        </Grid>
                        <Grid item xs={10}>
                            <input type="text" className="inputStyles inputStylesBorderNone" placeholder="เบอร์โทรศัพท์" {...register("username", { required: true, maxLength: 30, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="30" required />
                        </Grid>
                    </Grid>
                    {
                        renderErrorUsername()
                    }
                </div>
            </div>

            <br />
            <div className="center">
                <div className="inputPaddingTop">
                    <button type="submit" className="btn btnConfirm btnHover btnWidthLarge btnRadius textBoldHight">
                        ขอรหัสผ่านใหม่
                    </button>
                </div>
            </div>
        </form>
    );
}