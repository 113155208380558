import { useEffect, useState } from 'react';

// Hooks
import { useAddToHomescreenPrompt } from "@utility/hooks/useAddToHomescreenPrompt";

// Component
import { Grid } from '@mui/material';
import ImageShow from "views/components/ImageShow";

export default function NotificationSubscription({ notificationStatus, configInstallPWA, setStorage }) {
    const [isAppInstalled, setIsAppInstalled] = useState(false);
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    // const applicationServerKeyData = 'BIVW5jjodYeBNcX5fO1S9CinSgldVY0JTbFRCyZ_auqrxL2BJOXlXiSE8nEdRk2BiCneGHMTwSqe3cAN5LlEXs4';
    let initializedEvent = false;

    const handleSubscribeClick = async () => {
        promptToInstall();
        setStorage("notificationStatus", true);
        setIsAppInstalled(true);
    };

    const handleCancelClick = () => {
        setStorage("notificationStatus", true);
        setIsAppInstalled(true);
    };


    const isPWAInstalled = async () => {
        try {
            const relatedApps = await navigator.getInstalledRelatedApps();
            const installed = relatedApps.find((app) => app.url === configInstallPWA.data.url);
            if (installed !== undefined) {
                setIsAppInstalled(true);
                setStorage("notificationStatus", true);
            }
            else {
                setIsAppInstalled(false);
            }
        } catch (error) {
            setIsAppInstalled(true);
            setStorage("notificationStatus", true);
        }
    };

    useEffect(() => {
        // ดัก Event ให้ทำงานก่อนเขียน Component
        if (!initializedEvent) {
            initializedEvent = true;

            // ตรวจสอบบราวเซอร์รองรับการติดตั้งแอพที่หน้าจอโฮม
            if ('getInstalledRelatedApps' in window.navigator) {
                if (prompt === null) {
                    isPWAInstalled(prompt);
                }
            }
            else {
                setStorage("notificationStatus", true);
                setIsAppInstalled(true);
            }
        }
    }, [prompt]);

    // const reloadNotification = () => {
    //     if ('serviceWorker' in navigator && 'PushManager' in window) {
    //         navigator.serviceWorker.ready
    //             .then((registration) => {
    //                 registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: applicationServerKeyData })
    //                     .then(() => {
    //                         const options = {
    //                             body: configInstallPWA.body,
    //                             icon: configInstallPWA.icon,
    //                             data: configInstallPWA.data,
    //                         };

    //                         return registration.showNotification(configInstallPWA.title, options);
    //                     })
    //                     .catch((error) => {
    //                         setIsAppInstalled(true);
    //                         console.error('เกิดข้อผิดพลาดในการส่งแจ้งเตือนติดตั้ง:', error);
    //                     });
    //             }).then(() => {
    //                 setIsAppInstalled(true);
    //             });
    //     }
    // };

    // useEffect(() => {
    //     if (notificationStatus) {
    //         if (configInstallPWA !== null) {
    //             if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
    //                 // แอพ PWA ถูกติดตั้งในหน้าจอโฮม
    //                 setIsAppInstalled(true);
    //                 setStorage("notificationStatus", true);
    //             }
    //             else {
    //                 // ไม่มีการติดตั้ง PWA ในหน้าจอโฮม
    //                 setIsAppInstalled(false);
    //             }

    //         }
    //     }
    // }, [configInstallPWA, notificationStatus]);

    // useEffect(() => {
    //     if (isAppInstalled === false) {
    //         reloadNotification();
    //     }
    // }, [isAppInstalled]);

    return (
        (prompt !== null && !isAppInstalled && notificationStatus) && (
            <div className="positionFixed topLeft leftCenter traslateXCenter zIndexLarge zIndexMax">
                <div className='pwaPopupInstallApp displayInlineBlock'>
                    <Grid container spacing={{ xs: 2, sm: 3 }}>
                        <Grid item xs={3}>
                            <ImageShow className="pwaIconSize" src={configInstallPWA?.icon} alt="ติดต่อพนักงาน สล็อตออนไลน์" />
                        </Grid>
                        <Grid item xs={9}>
                            <div className="pwaIconTitle textBoldMedium">
                                {configInstallPWA?.text}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <button className="btn btnPWACancel btnHover btnWidthFull btnRadiusLow" onClick={handleCancelClick}>ยกเลิก</button>
                        </Grid>
                        <Grid item xs={4}>
                            <button className="btn btnPWAInstall btnHover btnWidthFull btnRadiusLow" onClick={handleSubscribeClick}>ติดตั้งแอพ</button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    );
};