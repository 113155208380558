import { useState, useEffect } from "react";

export function useAddToHomescreenPrompt() {
    const [prompt, setState] = useState(null);
    const [loadComponent, setLoadComponent] = useState(false);
    let initializedEvent = false;

    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }
        // return Promise.reject(
        //     new Error(
        //         'Tried installing before browser sent "beforeinstallprompt" event'
        //     )
        // );
    };

    useEffect(() => {
        // ดัก Event ให้ทำงานก่อนเขียน Component
        if (!initializedEvent) {
            initializedEvent = true;
            setTimeout(setLoadComponent(true), 3000);
        }
    }, []);

    useEffect(() => {
        if (loadComponent) {
            const ready = (e) => {
                e.preventDefault();
                setState(e);
            };

            window.addEventListener("beforeinstallprompt", ready);

            return () => {
                window.removeEventListener("beforeinstallprompt", ready);
            };
            
        }
    }, [loadComponent]);

    return  [prompt, promptToInstall];
}