import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';

// @Utility
import { setStorage, getStorage } from "@utility/app/storage";

// Component
import GotoPage from "views/components/GotoPage";
import ProgressiveImage from "views/components/ProgressiveImage";

// Icon
import CloseIcon from '@mui/icons-material/Close';

// Style & CSS
import "assets/css/Modal.css";

const ImagePopup = ({ agentImage }) => {
    const pageRef = useRef();
    const { pathname } = useLocation();
    const [dataPopup, setDataPopup] = useState([]);
    const [showContent, setShowContent] = useState(false);

    const settings = {
        dots: true,
        // fade: true,
        infinite: true,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    const close = () => {
        setShowContent(false);
    };

    const gotoPage = (item) => {
        pageRef.current.gotoCampWithImage(item, null);
        setShowContent(false);
    };

    useEffect(() => {
        const isNumber = (n) => {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        };

        if (agentImage !== undefined && agentImage !== null) {
            const dataAgentImages = agentImage;
            const dataImagesPopup = dataAgentImages.filter((item) => {
                return item.image_position === "popup";
            });

            if (dataImagesPopup.length > 0) {
                const dataArr = dataImagesPopup.filter((item) => {
                    let timeStorage = getStorage(item.id);
                    let statusPopup = false;
                    let timeNext = (new Date().getTime() / 1000) + ((item?.image_frequenc === undefined || timeStorage === "undefined") ? 0 : item?.image_frequenc);
                    if (timeStorage === undefined || timeStorage === null || timeStorage === "undefined" || timeStorage === "NaN" || timeStorage === "null" || timeStorage === "" || timeStorage === "0") {
                        setStorage(item.id, timeNext);
                        statusPopup = true;
                    }
                    else {
                        if (isNumber(timeStorage)) {
                            let timeNow = (new Date().getTime() / 1000);
                            if (timeNow > Number(timeStorage)) {
                                setStorage(item.id, (Number(timeStorage) + ((item?.image_frequenc === undefined || timeStorage === "undefined") ? 0 : item?.image_frequenc)));
                                statusPopup = true;
                            }
                            else {
                                statusPopup = false;
                            }
                        }
                        else {
                            setStorage(item.id, timeNext);
                            statusPopup = true;
                        }
                    }

                    if (statusPopup) {
                        if (pathname === "/") {
                            return item.image_path === "" || item.image_path === "/";
                        }
                        else {
                            if (item.image_path === "*") {
                                return item.image_path;
                            }
                            else {
                                return item.image_path === pathname;
                            }
                        }
                    }
                });

                if (dataArr.length > 0) {
                    setDataPopup(dataArr);
                    setShowContent(true);
                }
            }
        }
    }, [agentImage, pathname]);

    return (
        showContent && (
            <>
                <GotoPage ref={pageRef}></GotoPage>

                <div id="modal">
                    <div className="contentPosition  zIndexLarge" style={{ height: "unset" }}>
                        <div className="boxBody">
                            <div className="box">
                                <div className="positionRelative">
                                    <div className="closeButtonPopup zIndexLarge" onClick={() => close()}>
                                        <CloseIcon className="iconImgPopup"></CloseIcon>
                                    </div>
                                </div>

                                <div className="boxContentWidthBigImage">
                                    {
                                        dataPopup !== null && (
                                            <Slider {...settings}>
                                                {
                                                    dataPopup.map((item) => {
                                                        return (
                                                            <div key={item.id} className="slideSize">
                                                                <ProgressiveImage className="imgPopup" placeholder="/images/bluring/520x520.jpg" src={item.image_picture} onClick={() => { gotoPage(item) }}></ProgressiveImage>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fixedBackdrop zIndexHight" onClick={close}></div>
                </div>
            </>
        )
    );
}

export default ImagePopup;