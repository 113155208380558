import { useForm } from 'react-hook-form';

// @Utility
import validateInput from "@utility/app/validateInput";

// Component
import Grid from '@mui/material/Grid';

// Icon
import PhoneIcon from '@mui/icons-material/Phone';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';

export default function Register01App(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const clickBank = (data) => {
        props.setSelectBank(data);
    };

    const renderErrorPhone = () => {
        if (errors.phone?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง
                </div>
            );
        }
        else if (errors.phone?.type === "pattern") {
            return (
                <div className="validateError">
                    เบอร์โทรศัพท์ที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ต้องใส่ขีด (-) ในเบอร์โทรศัพท์
            </div>
        );
    };

    const renderErrorBankAccount = () => {
        if (errors.bank_account?.type === "pattern") {
            return (
                <div className="validateError">
                    เลขบัญชีญธนาคารที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        } 
        else if (errors.bank_account?.type === "minLength") {
            return (
                <div className="validateError">
                    กรอกเลขบัญชีธนาคารให้ถูกต้อง
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ใช้อักขระพิเศษในเลขบัญชีญธนาคาร
            </div>
        );
    };

    const renderErrorBankAccountName = () => {
        if (errors.bank_account_name?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณากรอกชื่อบัญชีธนาคาร
                </div>
            );
        }
        else if (errors.bank_account_name?.type === "pattern") {
            return (
                <div className="validateError">
                    ชื่อบัญชีที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                โปรดตรวจสอบชื่อ เเละหมายเลขบัญชี หากไม่ถูกต้องจะไม่สามารถฝากถอนได้
            </div>
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <br />
                        <div className="inputPaddingRegisterTop">
                            <div className="inputTextLabel">
                                เบอร์โทรศัพท์*
                            </div>
                            <Grid container alignItems="center" className="inputStyles center" >
                                <Grid item xs={2}>
                                    <PhoneIcon className="iconSize" />
                                </Grid>
                                <Grid item xs={10}>
                                    <input type="tel" className="inputStyles inputStylesBorderNone" placeholder="เบอร์โทรศัพท์" {...register("phone", { required: true, maxLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="10" required />
                                </Grid>
                            </Grid>
                            {
                                renderErrorPhone()
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="inputPaddingRegisterTop">
                            <div className="inputTextLabel">
                                กรุณาเลือกธนาคาร
                            </div>
                            <Grid container spacing={1}>
                                {
                                    props?.bankList !== undefined && (
                                        props.bankList !== null && (
                                            props.bankList.map((item, index) => (
                                                <Grid key={index} item xs={2}>
                                                    <img className={["imgBank", props.selectBank === item.bank_switfcode ? "" : "imgBankNotSelect"].join(" ")} src={item.bank_img} alt="bank list" onClick={() => clickBank(item.bank_switfcode)} />
                                                </Grid>
                                            ))
                                        )
                                    )
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="inputPaddingRegisterTop">
                            <div className="inputTextLabel">
                                เลขบัญชีธนาคาร*
                            </div>
                            <Grid container alignItems="center" className="inputStyles center" >
                                <Grid item xs={2}>
                                    <AccountBalanceIcon className="iconSize" />
                                </Grid>
                                <Grid item xs={10}>
                                    <input type="text" className="inputStyles inputStylesBorderNone" placeholder="กรอกเลขบัญชีธนาคาร" {...register("bank_account", { required: true, maxLength: 13, minLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="13" required />
                                </Grid>
                            </Grid>
                            {
                                renderErrorBankAccount()
                            }
                        </div>
                    </Grid>
                    {
                        props.showAccName && (
                            <Grid item xs={12}>
                                <div className="inputPaddingRegisterTop">
                                    <div className="inputTextLabel">
                                        ชื่อ - นามสกุล*
                                    </div>
                                    <Grid container alignItems="center" className="inputStyles center" >
                                        <Grid item xs={2}>
                                            <PersonIcon className="iconSize" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <input type="text" className="inputStyles inputStylesBorderNone" placeholder="ชื่อ - นามสกุล" {...register("bank_account_name", { required: true, maxLength: 50, pattern: /^[a-zA-Zก-๙ ]+$/i })} onKeyPress={(event) => validateInput(event, "")} maxLength="50" required />
                                        </Grid>
                                    </Grid>
                                    {
                                        renderErrorBankAccountName()
                                    }
                                </div>
                            </Grid>
                        )
                    }
                </Grid>

                <div className="inputPaddingTop center boxMarginTop">
                    <button type="submit" className="btn btnLogin btnHover btnWidthLarge btnRadius textBoldHight">
                        สมัครสมาชิก
                    </button>
                </div>
            </form>

            <div className="boxMarginTop center">
                <div className="inputNotRegister">
                    เป็นสมาชิกอยู่เเล้ว?
                </div>

                <div className="boxMarginTop">
                    <button className="btn btnConfirm btnHover btnWidthLarge btnRadius textBoldHight" onClick={() => { props.switchChanel("login") }}>
                        เข้าสู่ระบบ
                    </button>
                </div>
            </div>
        </>
    );
}