import {
    LOAD_SETTING
} from '../../actions/app/dataSetting';

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SETTING:
            return { ...state, result: action.payload.items };
        default:
            return state;
    }
}