const SET_DATA_DEMO = 'app/SET_DATA_DEMO';

function setDataDemo(data) {
    return {
        type: SET_DATA_DEMO,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_DEMO,
    setDataDemo
}