// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { setDataDemo } from "@core/redux/actions/app/demo";
import { openAuthenApp } from "@core/redux/actions/auth/index";

// JWT & Auth
import { isUserLoggedIn } from "@core/auth/utils";
import { getToken } from "@core/auth/utils";

// Component
import ModalApp from "./Alert/ModalAlert";

function DemoApp({ demoData }) {
    const itemsGameDemo = useSelector(state => state.reduxGame.itemGameDemo);
    const dispatch = useDispatch();

    const onShow = (val) => {
        dispatch(setDataDemo(val));
    };

    const gotoLogin = () => {
        let myObj = {
            tab: "login",
            show: true
        };

        onShow(false);
        dispatch(openAuthenApp(myObj));
    };

    const genareateUrl = () => {
        if (itemsGameDemo !== null) {
            let mode = "demo";
            let item = itemsGameDemo;

            if (isUserLoggedIn()) {
                mode = "real";
            }

            if (item !== undefined && item !== null) {
                let urlOpenGame = "";

                if (item?.is_html) {
                    urlOpenGame = `${window.location.origin}/open-game/html?mode=${mode}&camp=${item?.camp.toLowerCase()}&code=${(item?.game_code === undefined || item?.game_code === null) ? item.code : item?.game_code}`;
                }
                else {
                    urlOpenGame = `${process.env.REACT_APP_API_URL}/general/generate/open-game/${mode}/${(item?.game_code === undefined || item?.game_code === null) ? item.code : item?.game_code}?url=${window.location.origin}&token=${getToken()}`;
                }

                return urlOpenGame;
            }
        }

        return "#";
    };

    return (
        <ModalApp show={demoData.show} onShow={onShow} closeBtn={true}>
            <div className="boxTitle center" style={{ color: "var(--bg-white)" }}>
                โหมดทดลองเล่นเกม
            </div>
            <div className="boxContentTitle contentMediumMarginTop center" style={{ color: "var(--bg-white)" }}>
                ยอดที่ได้รับจะไม่สามารถนำมาถอนเงินได้ หากต้องการวางเดิมพันกรุณา "<span className="demoComment">เข้าสู่ระบบ</span>"
            </div>
            <div className="boxMarginTop">
                <div className="center">
                    <a href={genareateUrl()} className="btn btnBlue btnHover btnWidthHight btnRadiusMedium textBoldHight" style={{ textDecoration: "unset" }} onClick={() => onShow(false)} target="_blank">
                        ทดลองเล่น
                    </a>
                    <div className="btn btnConfirm btnHover btnWidthHight btnRadiusMedium textBoldHight contentMediumMarginTop" onClick={gotoLogin}>
                        เข้าสู่ระบบ
                    </div>
                </div>

                <br />
            </div>
        </ModalApp>
    );
}

export default DemoApp;
