// Services
import { apiServices } from "@core/services/appService";

// @utility
import alertText from "@utility/app/alertText";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";
import { loadHomeData } from "@core/redux/actions/home/index";

const OPEN_PROMOTION_POPUP = 'app/promotion/OPEN_PROMOTION_POPUP';
const LOAD_PROMOTION_ALL = 'app/promotion/LOAD_PROMOTION_ALL';

function openPromotionPopup(data) {
    return {
        type: OPEN_PROMOTION_POPUP,
        payload: {
            items: data
        }
    }
}

// function loadCheckPromotion() {
//     return dispatch => {
//         dispatch(setLoadProcessing(true));
        
//         checkPromotionApply().then((data) => {
//             const statusCode = data.code;
//             dispatch(setLoadProcessing(false));

//             if (statusCode === 200 || statusCode === 0) {
//                 dispatch({
//                     type: LOAD_CHECK_PROMOTION,
//                     payload: {
//                         items: data.result
//                     }
//                 })
//             }
//             else {
//                 dispatch({
//                     type: LOAD_CHECK_PROMOTION,
//                     payload: {
//                         items: null
//                     }
//                 })
//             }
//         });
//     }
// };

function loadPromotionAll() {
    return dispatch => {
        apiServices({}, "get", `/general/promotion`).then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));
            const { code: statusCode } = data;

            if (statusCode === 0 || statusCode === 200) {
                dispatch({
                    type: LOAD_PROMOTION_ALL,
                    payload: {
                        items: data.result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_PROMOTION_ALL,
                    payload: {
                        items: []
                    }
                })
            }
        });
    }
};

function cancelPromotion(gotoPage) {
    return dispatch => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));
        apiServices({}, "post", "/customer/promotion/cancel").then((data) => {
            dispatch(setStatusSpinner({status: false, textProcess: null}));
            const { code: statusCode } = data;

            if (statusCode === 200 || statusCode === 0) {              
                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "ยกเลิกโปรโมชั่นสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));

                dispatch(loadHomeData());
                gotoPage();
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
};

export {
    OPEN_PROMOTION_POPUP,
    // LOAD_CHECK_PROMOTION,
    LOAD_PROMOTION_ALL,
    openPromotionPopup,
    // loadCheckPromotion,
    loadPromotionAll,
    cancelPromotion
}