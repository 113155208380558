// @Utility
import alertText from "@utility/app/alertText";

// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";
import { loadHomeData } from "@core/redux/actions/home/index";

const LOAD_DATA_INVITE_ALL = 'app/LOAD_DATA_INVITE_ALL';
const LOAD_MEMBER_INVITE = 'app/LOAD_MEMBER_INVITE';
const LOAD_CLIAM_INVITE = 'app/LOAD_CLIAM_INVITE';

function loadDataInviteAll() {
    return dispatch => {
        apiServices({}, "get", "/customer/affiliate/data").then((data) => {
            const { code: statusCode, result } = data;

            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_DATA_INVITE_ALL,
                    payload: {
                        items: result
                    }
                })
            }
        });
    }
};

function loadMemberInvite(limit = -1, offset = 0) {
    return dispatch => {
        apiServices({limit, offset}, "post", "/customer/affiliate/member").then((data) => {
            const { code: statusCode, result } = data;
    
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_MEMBER_INVITE,
                    payload: {
                        items: result
                    }
                })
            }
        });
    }
};

function loadCliamInvite(limit = -1, offset = 0) {
    return dispatch => {
        apiServices({limit, offset}, "post", "/customer/affiliate/history").then((data) => {
            const { code: statusCode, result } = data;
    
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_CLIAM_INVITE,
                    payload: {
                        items: result
                    }
                })
            }
        });
    }
};

function claimInviteAll() {
    return dispatch => {
        dispatch(setStatusSpinner({status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..."}));

        apiServices({}, "get", "/customer/affiliate/claim").then((data) => {
            const { code: statusCode } = data;
    
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "ถอนรายได้สำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));

                dispatch(loadCliamInvite());
                dispatch(loadDataInviteAll());
                dispatch(loadMemberInvite());
                dispatch(loadHomeData());
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
};

export {
    LOAD_DATA_INVITE_ALL,
    LOAD_MEMBER_INVITE,
    LOAD_CLIAM_INVITE,
    loadDataInviteAll,
    loadMemberInvite,
    loadCliamInvite,
    claimInviteAll
}