const SET_DATA_POSITION = 'app/SET_DATA_POSITION';

function setDataPosition(data) {
    return {
        type: SET_DATA_POSITION,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_POSITION,
    setDataPosition
}