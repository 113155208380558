// Component
import ImageShow from "views/components/ImageShow";

export default function Maintainer() {
    window.scrollTo({
        top: 0,
        left: 0
    });

    return <ImageShow className="imgError" src="/images/error/maintainer.webp" alt="สล็อตออนไลน์"></ImageShow>;
}