import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

// Auth 01
import { isToken, isUserLoggedIn } from "@core/auth/utils";

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";
import useParameterUrl from "@utility/hooks/useParameterUrl";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Routes
import { BrowserRouter as Router } from 'react-router-dom';
import ViewsRoutes from "./ViewsRoutes";

// @Utility
import { setStorage, getStorage, removeStorage } from "@utility/app/storage";
import loadLiveChat from "@utility/app/loadLiveChat";

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { setDataCMS } from "@core/redux/actions/app/cms";
import { loadSetting } from "@core/redux/actions/app/dataSetting";
import { loadDataCampAll } from "@core/redux/actions/camp/index";
import { openAuthenApp, loginToken } from "@core/redux/actions/auth/index";

// Component
import LoadSpinner from "views/components/LoadSpinner";
import ErrorApp from "views/components/Error/index";
import ConfigSet from "views/components/ConfigSet";
import NotificationSubscription from "views/components/NotificationSubscription";

// Component
// import Navbar from "views/components/Navbar";
// import NavbarSub from "views/components/NavbarSub";
// import WrapperContainer from "views/components/WrapperContainer";
// import Loadding from "views/components/Loadding";

const Layouts = ({ domain }) => {
    const dispatch = useDispatch();
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);
    const parsed = useParameterUrl();
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const { login, register, affiliate, refer, ref, market, token } = parsed;
    const reduxSpinner = useSelector(state => state.reduxAppSpinner.data);
    const [dataSettingError, setDataSettingError] = useState(null);
    const [dataSettingOffline, setDataSettingOffline] = useState(null);
    const [maintainnerStatus, setMaintainnerStatus] = useState(null);
    const [configInstallPWA, setConfigInstallPWA] = useState(null);
    const [notificationStatus, setNotificationStatus] = useState("load");

    const setLoadDataCms = (dataCms) => {
        dispatch(setDataCMS(dataCms));
        setStorage("data_cms", JSON.stringify(dataCms));
        document.getElementsByTagName("body")[0].style.background = dataCms["bg-floor"];
        // console.log(dataCms)
        document.documentElement.style.setProperty("--bg-navbar", dataCms["bg-navbar"]);
        document.documentElement.style.setProperty("--bg-primary", dataCms["bg-primary"]);
        document.documentElement.style.setProperty("--bg-secondary", dataCms["bg-secondary"]);
        document.documentElement.style.setProperty("--bg-confirm", dataCms["bg-confirm"]);
        document.documentElement.style.setProperty("--text-bt-confirm", dataCms["text-bt-confirm"]);
        document.documentElement.style.setProperty("--bt-login", dataCms["bt-login"]);
        document.documentElement.style.setProperty("--text-bt-login", dataCms["text-bt-login"]);
        document.documentElement.style.setProperty("--bt-confirm", dataCms["bt-confirm"]);
        document.documentElement.style.setProperty("--bg-brand", dataCms["bg-brand"]);
        document.documentElement.style.setProperty("--bg-label-money", dataCms["bg-label-money"]);
        document.documentElement.style.setProperty("--bg-camp", dataCms["bg-camp"]);
        document.documentElement.style.setProperty("--text-secondary", dataCms["text-secondary"]);
        document.documentElement.style.setProperty("--bg-card", dataCms["bg-card"]);
        document.documentElement.style.setProperty("--bg-select", dataCms["bg-select"]);
        document.documentElement.style.setProperty("--bg-floor", dataCms["bg-floor"]);
        document.documentElement.style.setProperty("--bg-menu-floor", dataCms["bg-menu-floor"]);
        document.documentElement.style.setProperty("--bg-label-text", dataCms["bg-label-text"]);
        document.documentElement.style.setProperty("--bt-sucess", dataCms["bt-sucess"]);
        document.documentElement.style.setProperty("--bt-danger", dataCms["bt-danger"]);
        document.documentElement.style.setProperty("--bg-warning", dataCms["bg-warning"]);
        document.documentElement.style.setProperty("--bg-blue", dataCms["bg-blue"]);
        document.documentElement.style.setProperty("--bg-black", dataCms["bg-black"]);
        document.documentElement.style.setProperty("--bg-white", dataCms["bg-white"]);
        document.documentElement.style.setProperty("--bg-text-main", dataCms["bg-text-main"]);
        document.documentElement.style.setProperty("--bg-play-game", dataCms["bg-play-game"]);
        document.documentElement.style.setProperty("--bg-popup", dataCms["bg-popup"]);
        document.documentElement.style.setProperty("--bg-floor-popup", dataCms["bg-floor-popup"]);
        document.documentElement.style.setProperty("--label-name", dataCms["label-name"]);
        document.documentElement.style.setProperty("--bg-rank-a", dataCms["bg-rank-a"]);
        document.documentElement.style.setProperty("--bg-rank-b", dataCms["bg-rank-b"]);

        if (dataCms["google_key"] !== "") {
            TagManager.initialize({ gtmId: dataCms["google_key"] });
        }

        let url_domain = `${window.location.protocol}//${window.location.host}`;
        let myDynamicManifest = {
            "short_name": dataCms["manifest_short_name"],
            "name": dataCms["manifest_name"],
            "icons": [
                {
                    "src": dataCms?.manifest_icon_xxl !== undefined ? dataCms?.manifest_icon_xxl : "",
                    "type": "image/webp",
                    "sizes": "512x512"
                },
                {
                    "src": dataCms?.manifest_icon_xxl_png !== undefined ? dataCms?.manifest_icon_xxl_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "512x512"
                },
                {
                    "src": dataCms?.manifest_icon_xl !== undefined ? dataCms?.manifest_icon_xl : "",
                    "type": "image/webp",
                    "sizes": "196x196"
                },
                {
                    "src": dataCms?.manifest_icon_xl_png !== undefined ? dataCms?.manifest_icon_xl_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "196x196"
                },
                {
                    "src": dataCms?.manifest_icon_lg !== undefined ? dataCms?.manifest_icon_lg : "",
                    "type": "image/webp",
                    "sizes": "144x144"
                },
                {
                    "src": dataCms?.manifest_icon_lg_png !== undefined ? dataCms?.manifest_icon_lg_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "144x144"
                },
                {
                    "src": dataCms?.manifest_icon_md !== undefined ? dataCms?.manifest_icon_md : "",
                    "type": "image/webp",
                    "sizes": "128x128"
                },
                {
                    "src": dataCms?.manifest_icon_md_png !== undefined ? dataCms?.manifest_icon_md_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "128x128"
                },
                {
                    "src": dataCms?.manifest_icon_sm !== undefined ? dataCms?.manifest_icon_sm : "",
                    "type": "image/webp",
                    "sizes": "96x96"
                },
                {
                    "src": dataCms?.manifest_icon_sm_png !== undefined ? dataCms?.manifest_icon_sm_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "96x96"
                },
                {
                    "src": dataCms?.manifest_icon_xs !== undefined ? dataCms?.manifest_icon_xs : "",
                    "type": "image/webp",
                    "sizes": "64x64"
                },
                {
                    "src": dataCms?.manifest_icon_xs_png !== undefined ? dataCms?.manifest_icon_xs_png : "",
                    "type": "image/png, image/PNG, image/jpg, image/JPEG",
                    "sizes": "64x64"
                }
            ],
            "scope": dataCms["manifest_scope"],
            "start_url": url_domain,
            "display": dataCms["manifest_display"],
            "theme_color": dataCms["manifest_theme_color"],
            "background_color": dataCms["manifest_background_color"]
        };

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector("#manifest-project").setAttribute("href", manifestURL);
        document.querySelector("#apple-touch-project").setAttribute("href", dataCms["manifest_icon_lg"]);
        document.querySelector("#icon-rel").setAttribute("href", dataCms["rel_icon_big"]);

        if (dataCms?.section_notificaion_pwa === 1 || dataCms?.section_notificaion_pwa === "1") {
            setConfigInstallPWA({
                title: `แอพ ${dataCms["manifest_name"]}`,
                body: `คลิกเพื่อติดตั้งแอพ ${dataCms["manifest_name"]}`,
                text: dataCms["title_notificaion_pwa"],
                icon: dataCms["manifest_icon_lg"],
                data: {
                    url: url_domain,
                },
            });

            const notificationData = getStorage("notificationStatus");
            setNotificationStatus(notificationData);
        }
        else {
            setNotificationStatus("load");
        }
    };

    const renderRoutes = () => {
        return (
            isToken() && (
                <Router>
                    <ViewsRoutes />
                    <ConfigSet />
                </Router>
            )
        );
    };

    const renderContent = () => {
        if (!dataSettingError && !maintainnerStatus && !dataSettingOffline) {
            return renderRoutes();
        }

        if (maintainnerStatus) {
            return <ErrorApp typeError="maintainer"></ErrorApp>;
        }

        if (dataSettingError) {
            return <ErrorApp typeError="noData"></ErrorApp>;
        }

        if (dataSettingOffline) {
            return <ErrorApp typeError="offline"></ErrorApp>;
        }

        return null;
    };

    const readObjJson = (jsonString) => {
        try {
            const dataJson = JSON.parse(jsonString);
            return dataJson;
        } catch (error) {
            return null;
        }
    };

    const reloadSetting = (setting) => {
        try {
            if (setting?.cms !== null && setting?.cms !== undefined) {
                removeStorage("data_cms");
                const dataCms = setting?.cms;
                setLoadDataCms(dataCms);
                // setProcessingNavbar(true);
            }
            else {
                const checkCookie = getStorage("data_cms");
    
                if (checkCookie !== undefined && checkCookie !== null && checkCookie !== "" && checkCookie !== " ") {
                    const data_cms = readObjJson(checkCookie);
    
                    if (data_cms !== null) {
                        setLoadDataCms(data_cms);
                    }
                    else {
                        setDataSettingOffline(true);
                    }
                }
            }
    
            if (setting.agent.agent_staus === 2 || setting.agent.agent_staus === 0) {
                setMaintainnerStatus(true);
            }
            else {
                setMaintainnerStatus(false);
            }
        } catch (error) {
            setDataSettingOffline(true);
        }
    };

    useEffect(() => {
        if (dataSetting !== null) {
            reloadSetting(dataSetting);
        }

        if (dataSetting === null || dataSetting === undefined) {
            if (dataSettingError || dataSettingOffline) {
                const getSetting = getStorage("setting");

                if (getSetting !== undefined && getSetting !== null && getSetting !== "" && getSetting !== " ") {
                    const setSetting = readObjJson(getSetting);

                    if (setSetting !== null) {
                        reloadSetting(setSetting);
                    }
                    else {
                        setDataSettingOffline(true);
                    }
                }
            }
        }
    }, [dataSetting]);

    useEffect(() => {
        setStorage("allusion", document.referrer);

        if (isUserLoggedIn() === false) {
            // SHOW หน้าเข้าสู่ระบบ
            if (login === true || login === "true") {
                dispatch(openAuthenApp({ tab: "login", show: true }));
            }

            // SHOW หน้าสมัครสมาชิก
            if (register === true || register === "true") {
                dispatch(openAuthenApp({ tab: "register", show: true }));
            }
        }

        // เก็บข้อมูลเพื่อนที่เชิญ
        if (affiliate !== undefined) {
            setStorage("affiliate", affiliate)
        }

        // อ้างอิง
        if (refer !== undefined) {
            setStorage("refer", refer)
        } else if (ref !== undefined) {
            setStorage("refer", ref)
        }

        // การตลาด
        if (market !== undefined) {
            setStorage("market", market)
        }
    }, [login, register, affiliate, refer, ref, dispatch]);

    useEffectOnce(() => {
        if (isToken()) {
            dispatch(loadSetting({ "domain": domain }, setDataSettingError, setDataSettingOffline));
        }
    }, [isToken()]);

    useEffect(() => {
        if (dataSettingError === false) {
            dispatch(loadDataCampAll({ "type": 99 }));
        }
    }, [dataSettingError]);

    useEffect(() => {
        if (dataSetting !== null) {
            let dataAgentContact = dataSetting?.agent_contact;
            if (dataAgentContact !== undefined && dataAgentContact !== null) {
                if (dataAgentContact.length > 0) {
                    let dataLiveChat = dataAgentContact.filter((item) => (item.contact_channel === 1));

                    if (dataLiveChat.length > 0) {
                        loadLiveChat(dataLiveChat[0].channel_appid);
                    }
                }
            }
        }
    }, [dataSetting]);

    useEffectOnce(() => {
        if (isUserLoggedIn() === false) {
            if (token !== null && token !== undefined && token !== "undefined" && token !== "") {
                dispatch(loginToken({ token }));
            }
        }
    }, [isUserLoggedIn()]);

    return (
        <>
            {
                reduxSpinner.status && (
                    <LoadSpinner textProcess={reduxSpinner.textProcess}></LoadSpinner>
                )
            }

            {
                renderContent()
            }
            {
                (notificationStatus !== "load" && isLargeScreen && (notificationStatus === null || notificationStatus === undefined || notificationStatus === "")) && (
                    configInstallPWA !== null && (
                        <NotificationSubscription
                            setStorage={setStorage}
                            configInstallPWA={configInstallPWA}
                            notificationStatus={notificationStatus === null || notificationStatus === undefined || notificationStatus === ""}
                        />
                    )
                )
            }
        </>
    );
}

export default Layouts;